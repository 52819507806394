import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Select from 'components/Select/desktop';
import Button from 'components/Button';
import InputDate from 'components/InputDate/desktop';
import Input from 'components/Input';

import DaysFilter from 'features/reports/view/desktop/DaysFilter';

import { providerItems, gameTypes, statusBets } from 'features/reports/data';

import { actions } from 'features/reports/redux';
import { actions as userActions } from 'features/users/redux';

import useTree from 'hooks/useTree';

import './FilterBlock.scss';
import SearchInput from 'components/SearchInput';

const b = block('filter-block-player-history');

const FilterBlock = ({ count, currentPage, onPageClick }) => {
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();
  const { getTreeById } = useTree();
  const statisticAgentPlayer = useSelector(state => state.reports.statisticAgentPlayer);

  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'week')),
    endDate: new Date(dayjs().add(0, 'day')),
  });
  const [activeDay, setActiveDay] = useState('');
  const [filters, setFilters] = useState({
    user: statisticAgentPlayer.username,
    username: statisticAgentPlayer.username,
    provider: providerItems[0],
    gameType: gameTypes[0],
    statusBet: statusBets[0],
  });
  const [searchResults, setSearchResults] = useState(null);

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }

    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  // const handleUsernameChange = useCallback(
  //   e => {
  //     setFilters({ ...filters, [e.currentTarget.name]: e.currentTarget.value.toLowerCase() });
  //   },
  //   [filters],
  // );

  const getUsers = useCallback(
    data => {
      return dispatch(userActions.getUsersListForSearch(data));
    },
    [dispatch],
  );

  const handleSearchUser = useCallback(
    async value => {
      setFilters({ ...filters, user: value.toLowerCase() });

      if (value) {
        try {
          const data = await getUsers({ username: value });
          setSearchResults(data);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [filters, getUsers],
  );

  const handleProviderChange = value => {
    const providerItem = providerItems.find(item => item.value === value);
    setFilters({ ...filters, provider: providerItem });
  };

  const handleGameTypeChange = value => {
    const gameType = gameTypes.find(item => item.value === value);

    setFilters({
      ...filters,
      gameType: gameType,
      provider: {
        name: 'all',
        value: '',
      },
    });
  };

  const handleStatusBetChange = value => {
    const statusBet = statusBets.find(item => item.value === value);
    setFilters({ ...filters, statusBet: statusBet });
  };

  useEffect(() => {
    onClickSearch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, currentPage]);

  useEffect(() => {
    return () => {
      dispatch(actions.setPlayerUsername(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSearch = withoutZeroPage => {
    getTreeById(filters.user || '').then(upperAgent => upperAgent && handleSearch(upperAgent));
    if (!withoutZeroPage) onPageClick(0);
  };

  const handleSearch = upperAgent => {
    const requestedData = {
      username: filters.username,
      count,
      provider: filters.provider.value,
      upperAgentUsername: upperAgent && upperAgent.username,
      status: filters.statusBet.value,
      gameType: filters.gameType.value,
      page: currentPage,
      ...date,
    };
    dispatch(actions.getSlotsHistory(requestedData));
  };

  const onChangeActiveDay = useCallback(dayFilter => {
    setActiveDay(dayFilter);
    setDate({ ...date, beginDate: new Date(dayjs().add(dayFilter.value, 'day')) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAvailableProviders = gameType => {
    switch (gameType.value) {
      case 'slots':
        return [
          { name: 'All', value: '' },
          ...providerItems.filter(item =>
            [
              'PRAGMATIC-SLOTS',
              'ECONOMIX',
              'EVOPLAY',
              'SLOTEGRATOR',
              'SOFTGAMING-SLOTS',
              'BSW',
              'INBET',
              'FLG',
              'RUBYPLAY',
              'CASIMI',
              'FIABLE',
            ].includes(item.value),
          ),
        ];
      case 'live-casino':
        return [
          { name: 'All', value: '' },
          ...providerItems.filter(item => ['TVBET', 'XPG', 'PRAGMATIC-LIVE-CASINO', 'POPOKGAMING', 'EZUGI'].includes(item.value)),
        ];
      case 'virtual-games':
        return [{ name: 'All', value: '' }, ...providerItems.filter(item => ['GOLDENRACE'].includes(item.value))];
      case 'sport':
        return [{ name: 'All', value: '' }, ...providerItems.filter(item => ['DIGITAIN'].includes(item.value))];
      default:
        return providerItems;
    }
  };

  return (
    <div className={b()}>
      <div className={b('header')}>{locale.filter}</div>
      <div className={b('top')}>
        <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} />
        <div className={b('or')}>or</div>
        <div className={b('date')}>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateFrom}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.beginDate} onChange={onChangeBeginDate} />
            </div>
          </div>
          <div className={b('date-row')}>
            <div className={b('title')}>{locale.dateTo}</div>
            <div className={b('date-input')}>
              <InputDate selected={date.endDate} onChange={onChangeEndDate} />
            </div>
          </div>
        </div>
      </div>
      <div className={b('bottom')}>
        <div className={b('inputs')}>
          <div className={b('input')}>
            <div className={b('title')}>{locale.user}</div>
            {/* <Input size="low" value={filters.username} callBack={handleUsernameChange} placeholder={locale.user} name="username" /> */}
            <SearchInput
              value={filters.username}
              items={searchResults}
              onChange={handleSearchUser}
              placeholder={`${locale.search} ${locale.user}`}
            />
          </div>
          <div className={b('input')}>
            <div className={b('title')}>{locale.gameType}</div>
            <Select name="gameType" items={gameTypes} activeItem={filters.gameType} onChange={handleGameTypeChange} />
          </div>
          <div className={b('input')}>
            <div className={b('title')}>{locale.provider}</div>
            <Select
              name="provider"
              items={getAvailableProviders(filters.gameType)}
              activeItem={filters.provider}
              onChange={handleProviderChange}
            />
          </div>
          <div className={b('input')}>
            <div className={b('title')}>{locale.statusBet}</div>
            <Select
              name="statusBets"
              items={statusBets}
              activeItem={filters.statusBet}
              onChange={handleStatusBetChange}
            />
          </div>
        </div>
        <div className={b('button')}>
          {/* <div className={b('title')} style={{ color: 'transparent' }}>
            transparent placeholder
          </div> */}
          <Button size="low" callBack={() => onClickSearch()} text={locale.acceptFilter} />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
